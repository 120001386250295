import { gql } from '@apollo/client';
import { requestGql } from 'modules/common/utils/request-gql';
import { ContractTypeEnum } from 'modules/contract-details/enums';
import { ContractDetailsInterface } from 'modules/contract-details/interfaces';

export interface FetchContractsDetailsArgsInterface {
  id: string;
  type: ContractTypeEnum;
  kunnr: string;
}

export interface UseFetchContractsHookInterface {
  fetchContractDetails: (query?: FetchContractsDetailsArgsInterface) => Promise<ContractDetailsInterface>;
}

export const useContractDetails = (): UseFetchContractsHookInterface => {
  const fetchContractDetails = (args: FetchContractsDetailsArgsInterface): Promise<ContractDetailsInterface> =>
    requestGql(
      {
        query: gql`
          query ContractDetails($id: ID!, $kunnr: String!, $type: ContractTypeEnum!) {
            contractDetails(id: $id, kunnr: $kunnr, type: $type) {
              helmContractId
              customerContractReference
              currencyCode
              firstProductId
              type
              businessPartner {
                kunnr
                company
              }
              firstProductName
              orderQuantity
              contractPeriod {
                startDate
                endDate
                value
              }
              products {
                name
                formulaToDeterminePrice
                quality
                packagingUnit
                quantity
              }
              addresses {
                billingAddress
                sellingAddress
                payingAddress
                shippingAddress{
                  id
                  displayAddress
                }
              }
              incotermVersion
              incoterm
              incotermLocation
              paymentTerm
              quantitativeUnit
              packagingInformation
              qualityInformation
              priceInformation
              headerInformation
              logisticsInformation
              warehouseInformation
              paymentInformation
              createdByEmailAddress
              createdByUsername
            }
          }
        `,
        variables: {
          id: args.id,
          type: args.type,
          kunnr: args.kunnr,
        },
      },
      null,
      'contractDetails',
    );

  return {
    fetchContractDetails,
  };
};

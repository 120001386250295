import { Box, Divider, Grid } from '@mui/material';
import { Charts } from 'modules/charts/components';
import { OrderEnum } from 'modules/common/enums';
import { DocumentsOrderSortEnum } from 'modules/common/enums/documents-order-sort.enum';
import { useRouter, useWidth } from 'modules/common/hooks';
import { useCurrentBusinessPartnerInternal } from 'modules/current-business-partner-selector/hooks';
import { DeliveryTableSection } from 'modules/deliveries/components/delivery-table-section/delivery-table-section.component';
import {
  resetDeliveryFilter,
  resetInvoicesFilter,
  setDeliveryOrdertype,
  setDeliveryProductSearchByFilter,
  setDeliverySearchById,
  setDeliveryShipToAddressFilter,
  setInvoicesOrderType,
  setInvoicesProductSearchByFilter,
  setInvoicesSearchByTextFilter,
  setInvoicesShipToAddressFilter,
} from 'modules/filter-by/filter-by.slice';
import { InvoiceTableSectionComponent } from 'modules/invoices/components/invoice-table-section/invoice-table-section.component';
import { InvoicesLeftHeaderComponent } from 'modules/invoices/components/invoices-left-header/invoices-left-header.component';
import { InvoicesStatusPercentageBar } from 'modules/invoices/components/invoices-status-percentage-bar/invoices-status-percentage-bar.component';
import { HeaderRightComponentTypeEnum } from 'modules/invoices/enums/header-right-component-type.enum';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { OrderTypeEnum } from 'views/orders/enums';
import { useOverviewFilter } from 'views/overview/hooks/use-overview-filter.hook';
import { OverviewFilterPartial } from 'views/overview/partials';
import { OrdersCard } from 'views/overview/partials/orders-card/orders-card.partial';


export const OverviewLayoutPartial: FunctionComponent = () => {
  const {
    products,
    isLoading,
    selectedProduct,
    onProductChange,
    selectedOrderType,
    onOrderTypeChanged,
    filter,
    selectedShipToAddresses,
    onShipToAddressesChanged,
    onApply,
  } = useOverviewFilter();

  const { frameContractIds, productId, shipToAddressIds, kunnrIds } = filter;
  const dispatch = useDispatch();
  const { currentBusinessPartnerInternal } = useCurrentBusinessPartnerInternal();
  const router = useRouter();
  const screenWidth = useWidth();

  const shouldRenderCards = Boolean(filter.productId) && filter.orderType?.length > 0;

  const onFilterApply = useCallback(() => {
    if (onApply) {
      onApply();
    }
  }, [onApply]);

  const contractId = useMemo(
    () => (filter.orderType !== OrderTypeEnum.SPOT ? filter?.frameContractIds?.[0] : null),
    [filter?.frameContractIds, filter?.orderType],
  );
  const allSpotOrders = useMemo(() => (filter.orderType === OrderTypeEnum.SPOT ? true : false), [filter?.orderType]);

  const deliveryTableFilter = useMemo(
    () => ({
      ...(!!contractId && { contractId }),
      ...(!!allSpotOrders && { orderTypes: [OrderTypeEnum.SPOT] }),
      ...(!!filter.shipToAddressIds?.length && { shipToAddress: filter.shipToAddressIds }),
      productIds: [filter.productId],
    }),
    [contractId, allSpotOrders, filter],
  );

  const invoicesTableFilter = React.useMemo(
    () => ({
      kunnr: currentBusinessPartnerInternal?.kunnr,
      ...(!!contractId && { contractId }),
      ...(!!allSpotOrders && { documentOrderType: OrderTypeEnum.SPOT }),
      ...(!!shipToAddressIds?.length && { shipToAddressIds: filter.shipToAddressIds }),
      ...(productId ? { productIds: [productId] } : {}),
    }),
    [filter],
  );
  const invoicesTableState = React.useMemo(
    () => ({
      pageNumber: 0,
      pageSize: 3,
      filter: { ...invoicesTableFilter },
      order: {
        sort: DocumentsOrderSortEnum.ORDER_ID,
        order: OrderEnum.DESC,
      },
    }),
    [invoicesTableFilter],
  );

  const invoicesViewAllHandler = () => {
    //Because order type filter would only be activated if the selected type is SPOT
    //For type Call Off we would be sending in the contract ID instead that would populate the search inbox .
    dispatch(resetInvoicesFilter());
    if (selectedOrderType.id === OrderTypeEnum.SPOT) {
      dispatch(
        setInvoicesOrderType([
          {
            label: 'Spot',
            value: OrderTypeEnum.SPOT,
          },
        ]),
      );
    }
    if (selectedProduct) {
      dispatch(setInvoicesProductSearchByFilter({ product: [selectedProduct] }));
    }
    if (selectedShipToAddresses?.length) {
      dispatch(setInvoicesShipToAddressFilter(selectedShipToAddresses));
    }

    if (contractId) {
      dispatch(setInvoicesSearchByTextFilter(contractId));
    }

    void router.push(`/documents`);
  };

  const deliveryViewAllHandler = () => {
    dispatch(resetDeliveryFilter())
    if (selectedOrderType.id === OrderTypeEnum.SPOT) {
      dispatch(
        setDeliveryOrdertype([
          {
            value: OrderTypeEnum.SPOT,
          },
        ]),
      );
    }
    if (selectedProduct) {
      dispatch(setDeliveryProductSearchByFilter({ product: [selectedProduct] }));
    }
    if (selectedShipToAddresses?.length) {
      dispatch(setDeliveryShipToAddressFilter(selectedShipToAddresses));
    }

    if (contractId) {
      dispatch(
        setDeliveryOrdertype([
          {
            value: OrderTypeEnum.CALL_OFF,
          },
        ]),
      )
      dispatch(setDeliverySearchById(contractId));
    }

    void router.push(`/deliveries`);
  }
  return (
    <>
      <OverviewFilterPartial
        products={products}
        isLoading={isLoading}
        selectedProduct={selectedProduct}
        onProductChange={onProductChange}
        onOrderTypeChange={onOrderTypeChanged}
        onShipToAddressesChanged={onShipToAddressesChanged}
        selectedShipToAddresses={selectedShipToAddresses}
        orderType={selectedOrderType}
        onApply={onFilterApply}
        applyOnFirstLoad={true}
      />
      <Box sx={{ padding: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Charts
              contractId={frameContractIds && frameContractIds.length > 0 ? frameContractIds[0] : undefined}
              productId={productId}
              shipToAddress={shipToAddressIds}
              kunnr={kunnrIds && kunnrIds.length > 0 ? kunnrIds[0] : currentBusinessPartnerInternal?.kunnr}
              verticalDivider={/lg|xl/.test(screenWidth) ? true : false}
              componentDirection={/lg|xl/.test(screenWidth) ? 'col' : 'row'}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            {shouldRenderCards && (
              <OrdersCard
                selectedProduct={selectedProduct}
                selectedShipToAddresses={selectedShipToAddresses}
                selectedOrderType={
                  selectedOrderType?.labelPrefix?.includes('Call')
                    ? {
                      label: 'Call off',
                      value: OrderTypeEnum.CALL_OFF,
                    }
                    : {
                      label: 'Spot',
                      value: OrderTypeEnum.SPOT,
                    }
                }
                selectedContract={selectedOrderType?.labelPrefix?.includes('Call') ? selectedOrderType?.id || '' : ''}
                filter={{
                  productIds: [filter.productId],
                  orderTypes: [filter.orderType],
                  universalIds: filter.frameContractIds?.length ? filter.frameContractIds : undefined,
                  status: undefined,
                  shipToAddressIds: filter.shipToAddressIds,
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            {shouldRenderCards && (
              <DeliveryTableSection
                showHeader={true}
                showFilter={false}
                showSearch={false}
                deliveryTableStateInterface={{
                  pageSize: 3,
                  filter: deliveryTableFilter,
                }}
                paginationEnabled={false}
                viewAllHandler={deliveryViewAllHandler}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <InvoiceTableSectionComponent
              headerLeftComponent={<InvoicesLeftHeaderComponent />}
              headerRightComponentType={HeaderRightComponentTypeEnum.viewAll}
              invoicesTableState={invoicesTableState}
              onRightHeaderComponentClick={invoicesViewAllHandler}
              paginationEnabled={false}
            />
            <Divider />
            <InvoicesStatusPercentageBar invoicesTableFilter={invoicesTableFilter} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

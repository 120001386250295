import { Box, Button, Grid } from '@mui/material';
import uniqBy from 'lodash/uniqBy';
import { AddressInterface } from 'modules/addresses/interfaces';
import { CustomLabel } from 'modules/common/components/custom-label/custom-label.component';
import { ProductInterface } from 'modules/common/interfaces/product.interface';
import { ContractTypeEnum } from 'modules/contract-details/enums';
import { AddressSearchWithOptions } from 'modules/forms/components/address-search-overview/address-search-overview.component';
import { OrderTypeAutocompleteOption } from 'modules/forms/components/order-type-autocomplete/interfaces';
import { OrderTypeAutocomplete } from 'modules/forms/components/order-type-autocomplete/order-type-autocomplete.component';
import {
  ProductAutocompleteOptionInterface,
  ProductAutocompleteOverview,
} from 'modules/forms/components/product-autocomplete-search';
import { useEffect, useMemo, useState } from 'react';
import { OrderTypeEnum } from 'views/orders/enums';
import { useOverviewFilterStyles } from 'views/overview/partials/overview-filter/overview-filter.styles';
interface OverviewFilterPartialProps {
  selectedProduct: ProductAutocompleteOptionInterface;
  onProductChange?: (product: ProductAutocompleteOptionInterface) => void;
  onOrderTypeChange?: (orderType: OrderTypeAutocompleteOption) => void;
  onShipToAddressesChanged?: (addresses: AddressInterface[]) => void;
  selectedShipToAddresses?: AddressInterface[];
  selectedOrderType?: OrderTypeAutocompleteOption;
  orderType: OrderTypeAutocompleteOption;
  onApply?: () => void;
  applyOnFirstLoad?: boolean;
  isLoading?: boolean;
  products?: ProductInterface[];
}

export const OverviewFilterPartial = ({
  selectedProduct,
  onProductChange,
  onOrderTypeChange,
  orderType,
  onShipToAddressesChanged,
  selectedShipToAddresses,
  onApply,
  applyOnFirstLoad,
  isLoading,
  products,
}: OverviewFilterPartialProps): React.ReactElement => {
  const classes = useOverviewFilterStyles();
  const [isFirstFilterApplied, setIsFirstFilterApplied] = useState(false);
  const orderTypeOptions = useMemo(() => {
    const selectedProductOrderType = products.find((item) => item.productId === selectedProduct?.id)
    const orderTypes = !!selectedProductOrderType?.orderType?.find((item) => item.salesDocument && !item.salesContract)
      ? [{
        id: ContractTypeEnum.SPOT,
        labelPrefix: 'Spot Orders',
      }] : [];

    const callOffs = selectedProductOrderType?.orderType?.filter((item) => !!item.salesContract)
    const options = (callOffs || []).map((item) => ({
      id: item.salesContract,
      labelPrefix: `Call off - ${item.salesContract}`,
      constractId: item.salesContract
    }))
    return uniqBy([...orderTypes, ...options], 'id');
  }, [selectedProduct, products]);

  const uniqueAddresses = useMemo(() => {
    const selectedProductItem = products?.find((item) => item.productId === selectedProduct?.id)
    const isCallOff = orderType?.contractId;
    const filterAddresses = selectedProductItem?.orderType?.filter(isCallOff ? (p) => p?.salesContract === orderType?.contractId : (p) => !!p?.salesDocument)
    const mappedAddresses = filterAddresses?.map((p) => ({ id: p?.addressDetails?.id, displayAddress: p?.addressDetails?.displayAddress }))
    return uniqBy(mappedAddresses, 'id');
  }, [orderType, products, selectedProduct])


  useEffect(() => {
    if (applyOnFirstLoad && !isFirstFilterApplied && selectedProduct && orderType) {
      onApply?.();
      setIsFirstFilterApplied(true);
    }
  }, [selectedProduct, orderType, isFirstFilterApplied]);

  const orderTypes = [];
  if (orderType?.id === OrderTypeEnum.SPOT) {
    orderTypes.push(OrderTypeEnum.SPOT);
  } else if (orderType?.id) {
    orderTypes.push(OrderTypeEnum.CALL_OFF);
  }

  return (
    <Box className={classes.container}>
      <Grid container xs={12} alignItems={'end'} gap={'24px'} margin={'0px !important'}>
        <Box className={classes.inputContainer}>
          <CustomLabel value='Products' />
          <ProductAutocompleteOverview
            products={products}
            isLoading={isLoading}
            inputStyle={{
              '& .MuiFormControl-root': {
                margin: '0px !important',
              },
            }}
            fetchOrderTypeFilterDetails
            isLabel={false}
            value={selectedProduct || null}
            onChange={onProductChange}
            placeholder="All Products"
            defaultToFirst={true}
          />
        </Box>
        <Box className={classes.inputContainer}>
          <OrderTypeAutocomplete
            inputStyle={{
              '& .MuiFormControl-root': {
                margin: '0px !important',
              },
            }}
            label={'Order Type'}
            options={orderTypeOptions}
            onChange={onOrderTypeChange}
            value={orderType}
            disabled={!selectedProduct?.id}
            defaultToFirst={true}
          />

        </Box>
        <Box className={classes.inputContainer} maxWidth={'28%'}>
          <AddressSearchWithOptions
            disabled={!orderType}
            options={uniqueAddresses}
            value={orderType ? selectedShipToAddresses : []}
            onChange={onShipToAddressesChanged}
            orderTypes={orderTypes}
            contractId={orderType?.id || null}
            productId={selectedProduct?.id}
            initiateFetch={!!selectedProduct?.id}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <Button
            disabled={!selectedProduct || !orderType}
            sx={{ height: 42, width: '100%' }}
            fullWidth={true}
            variant="contained"
            color="primary"
            size='large'
            onClick={onApply}
          >
            Apply
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};
